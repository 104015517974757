import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UriService {
  baseURI: string;
  newLoginUrl: string;
  poiReportUrl: string;
  resetUrl: string;
  loginUrl: string;
  urlReport: string;
  urlSurveyIkcPedagogicalEmployee: string; // Location survey Ikc

  urlSurveyBookstartLibraryUser: string; // libraryUser
  urlSurveyBookstartConsultant: string; // location survey
  urlSurveyBookstartPedagogicalEmployee: string; // group survey

  urlSurveyPoConsultant: string; // location survey
  urlSurveyPoTeacher: string; // group survey teacher
  urlSurveyPoStudent: string; // group survey student
  urlSurveyPoStudentQr: string; // group survey student
  urlSurveyPoDemo: string; // group survey demo

  urlSurveyVoConsultant: string; // location survey
  urlSurveyVoTeacher: string; // group survey teacher
  urlSurveyVoCoordinator: string; // group survey student
  urlSurveyVoCoordinatorQr: string; // group survey student
  urlSurveyVoDemo: string; // group survey demo

  urlSurveyMboConsultant: string; // location survey
  urlSurveyMboTeacher: string; // group survey teacher
  urlSurveyMboCoordinator: string; // group survey student
  urlSurveyMboCoordinatorQr: string; // group survey student
  urlSurveyMboDemo: string; // group survey demo

  urlSurveyPaboConsultant: string; // location survey
  urlSurveyPaboTeacher: string; // group survey teacher
  urlSurveyPaboCoordinator: string; // group survey student
  urlSurveyPaboCoordinatorQr: string; // group survey student
  urlSurveyPaboDemo: string; // group survey demo
  urlAuthorize: string;
  urlResponse: string;
  constructor(@Inject(DOCUMENT) document: Document) {
    const baseUri = document.baseURI;
    this.baseURI = baseUri;
    this.newLoginUrl = `${baseUri}auth/login/?userid={0}&c={1}`;
    this.resetUrl = `${baseUri}auth/reset-password/?userid={0}&c={1}`;
    this.loginUrl = `${baseUri}auth/login/`;
    this.urlReport = `${baseUri}RR/`;
    this.poiReportUrl = `${baseUri}PoiReport/`;
    this.urlSurveyIkcPedagogicalEmployee = `${baseUri}PH/`;

    this.urlSurveyBookstartLibraryUser = `${baseUri}BM/`;
    this.urlSurveyBookstartPedagogicalEmployee = `${baseUri}BP/`;
    this.urlSurveyBookstartConsultant = `${baseUri}BV/`;

    this.urlSurveyPoConsultant = `${baseUri}PC/`;
    this.urlSurveyPoTeacher = `${baseUri}PK/`;
    this.urlSurveyPoStudent = `${baseUri}PL/`;
    this.urlSurveyPoStudentQr = `${baseUri}PQ/`;
    this.urlSurveyPoDemo = `${baseUri}PD/`; //demo

    this.urlSurveyVoConsultant = `${baseUri}VC/`;
    this.urlSurveyVoTeacher = `${baseUri}VK/`;
    this.urlSurveyVoCoordinator = `${baseUri}VL/`;
    this.urlSurveyVoCoordinatorQr = `${baseUri}VQ/`;
    this.urlSurveyVoDemo = `${baseUri}VD/`; //demo

    this.urlSurveyMboConsultant = `${baseUri}MC/`; //TODO: navragen wat dit doet
    this.urlSurveyMboTeacher = `${baseUri}MK/`;
    this.urlSurveyMboCoordinator = `${baseUri}ML/`;
    this.urlSurveyMboCoordinatorQr = `${baseUri}MQ/`;
    this.urlSurveyMboDemo = `${baseUri}MD/`; //demo

    this.urlSurveyPaboConsultant = `${baseUri}PAC/`; //TODO: navragen wat dit doet
    this.urlSurveyPaboTeacher = `${baseUri}PAK/`;
    this.urlSurveyPaboCoordinator = `${baseUri}PAL/`;
    this.urlSurveyPaboCoordinatorQr = `${baseUri}PAQ/`;
    this.urlSurveyPaboDemo = `${baseUri}PAD/`; //demo

    this.urlAuthorize = `${baseUri}Authorize/`;
    this.urlResponse = `${document.location.origin}/SurveyResponse/`.replace(':4211', ':4202'); // replace port number for local safari friendly bs-localhost.com
  }
}
