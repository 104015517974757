import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, of } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { Monitor, SchoolLocationClient, SchoolModel } from '../../../core/webapi';
import { MonitorProvider } from '../group-tabs/monitor.service';

@Injectable({ providedIn: 'root' })
export class SchoolLocationService {
  schoolLocations?: SchoolModel[];
  schoolLocationsBookStart?: SchoolModel[];
  schoolLocationsPo?: SchoolModel[];
  schoolLocationsVmbo?: SchoolModel[];
  schoolLocationsMbo?: SchoolModel[];
  schoolLocationsPabo?: SchoolModel[];
  readonly schoolLocations$ = this.schoolLocationClient.getSchools().pipe(
    tap((x) => this.initLocations(x)),
    shareReplay(1),
  );

  constructor(
    private schoolLocationClient: SchoolLocationClient,
    private config: MonitorProvider,
  ) {}

  init() {
    this.schoolLocations$.subscribe();
  }

  async refresh() {
    const locations = await lastValueFrom(this.schoolLocationClient.getSchools());
    this.initLocations(locations);
  }

  getSchools(): Observable<SchoolModel[]> {
    return this.schoolLocations$.pipe(switchMap(() => this.getSchoolByType()));
  }

  getAllSchools() {
    return this.schoolLocations$;
  }

  private getSchoolByType() {
    switch (this.config.portal) {
      case Monitor.BookStart:
        return of(this.schoolLocationsBookStart!);
      case Monitor.Po:
        return of(this.schoolLocationsPo!);
      case Monitor.Vmbo:
        return of(this.schoolLocationsVmbo!);
      case Monitor.Mbo:
        return of(this.schoolLocationsMbo!);
      case Monitor.Pabo:
        return of(this.schoolLocationsPabo!);
    }
  }

  private initLocations(x: SchoolModel[]): void {
    this.schoolLocations = x;
    this.schoolLocationsBookStart = x.filter((x) => x.monitor === Monitor.BookStart);
    this.schoolLocationsPo = x.filter((x) => x.monitor === Monitor.Po || x.monitor === Monitor.PoSpecial);
    this.schoolLocationsVmbo = x.filter((x) => x.monitor === Monitor.Vmbo);
    this.schoolLocationsMbo = x.filter((x) => x.monitor === Monitor.Mbo);
    this.schoolLocationsPabo = x.filter((x) => x.monitor === Monitor.Pabo);
  }
}
